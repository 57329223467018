import { Home } from './pages/home';
import { GlobalStyle } from './styles/global';

function App() {
  return (
    <>
    <GlobalStyle />
    <Home />
    </>
  );
}

export default App;
